<template>
<v-container
    id="login"
    class="fill-height justify-center"
    fluid
    tag="section"
    style="background-color: #242424"
  >
    <v-row justify="center">
      <v-col cols="4" sm="6" xs="10">
        <v-slide-y-transition appear>
          <BaseCard heading=" You have been signed out">
 
             <v-card-text class="text-center">

          <v-btn
                  large
                  color=""
                  depressed
                  class="v-btn--text primary--text"
                  submit
                  :to="{path: 'login'}"
                >
                  Let's Go
                </v-btn>
             </v-card-text>
          </BaseCard>
        </v-slide-y-transition>
      </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
  name: "logout",
  data: () => ({}),
  computed: {},
  mounted: function() {
    this.$store.dispatch("auth/logout");
  },
};
</script>
